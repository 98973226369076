
const parseBGGResponse = (item) => {
    const type = item._attributes.type

    switch (type) {
        case 'boardgame':
        case 'boardgameexpansion':
            return _BggThingResponse(item)
        default:
            return false
    }
}

const _BggThingResponse = (item) => {
    const linkData = {
        boardgamecategory: [],
        boardgamemechanic: [],
        boardgamedesigner: [],
        boardgameartist: []
    }

    item.link.forEach((link) => {
        const {_attributes} = link
        switch (_attributes.type) {
            case 'boardgamecategory':
                linkData['boardgamecategory'].push(_attributes.value)
                break;
            case 'boardgamemechanic':
                linkData['boardgamemechanic'].push(_attributes.value)
                break;
            case 'boardgamedesigner':
                linkData['boardgamedesigner'].push(_attributes.value)
                break;
            case 'boardgameartist':
                linkData['boardgameartist'].push(_attributes.value)
                break;
        }
    })
    
    let description = item.description._text || ''
    description = description.replace(/&#10;/g, '<br/>')
    description = description.replace(/&rsquo;/g, '\'')
    description = description.replace(/&mdash;/g, '-')

    const minPlayers = parseInt(item.minplayers._attributes.value)
    const maxPlayers = parseInt(item.maxplayers._attributes.value)
    const maxPlaytime = parseInt(item.maxplaytime._attributes.value)
    const minPlaytime = parseInt(item.minplaytime._attributes.value)
    const playtime = (minPlaytime === maxPlaytime) ? maxPlaytime : minPlaytime + ' - ' + maxPlaytime
    const players = (minPlayers === maxPlayers) ? maxPlayers : minPlayers + ' - ' + maxPlayers
    const rating = Math.round(parseFloat(item.statistics.ratings.average._attributes.value) * 10) / 10
    const weight = Math.round(parseFloat(item.statistics.ratings.averageweight._attributes.value) * 10) / 10

    const baseData = {
        id: parseInt(item._attributes.id),
        type: item._attributes.type,
        thumbnail: item.thumbnail !== undefined ? item.thumbnail._text : '',
        image: item.image !== undefined ? item.image._text : '',
        name: item.name[0] ? item.name[0]._attributes.value : item.name._attributes.value,
        yearpublished: parseInt(item.yearpublished._attributes.value),
        description,
        players,
        playtime,
        rating,
        weight,
        owned: parseFloat(item.statistics.ratings.owned._attributes.value),
    }

    return {...baseData, ...linkData}
}

module.exports = parseBGGResponse
