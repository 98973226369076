import axios from 'axios'
import qs from 'qs';

import config from '../config/config'

const STRAPI_API = axios.create({
    baseURL: config().API_URL
})

STRAPI_API.interceptors.response.use(onSuccess, onError)

function onSuccess(response) {
    const {status, statusText, data} = response
    if (status !== 200) {
        return Promise.reject(statusText)
    }
    return data
}

function onError(error) {
    return Promise.reject(false)
}

export default {
    getAllCategories: () => (STRAPI_API.get('/collections?' + qs.stringify({
        sort: ['position:asc'],
    }, {
        encodeValuesOnly: true,
    }))),
    getCategoriesVideo: () => (STRAPI_API.get('/collections?' + qs.stringify({
        sort: ['position:asc'],
        filters: {
            type: {$eq: 'videos'}
        },
    }, {
        encodeValuesOnly: true,
    }))),
    getCategoriesGames: () => (STRAPI_API.get('/collections?' + qs.stringify({
        sort: ['position:asc'],
        filters: {
            type: {$eq: 'games'}
        },
    }, {
        encodeValuesOnly: true,
    }))),
    getVideoById: (videoId) => (STRAPI_API.get('/videos?' + qs.stringify({
        filters: {
            video_id: {$eq: videoId}
        },
    }, {
        encodeValuesOnly: true,
    }))),
    getVideoBySlug: (slug) => (STRAPI_API.get('/videos?' + qs.stringify({
        filters: {
            slug: {$eq: slug}
        },
    }, {
        encodeValuesOnly: true,
    }))),
    getRecentVideos: (page = 1) => (STRAPI_API.get('/videos?' + qs.stringify({
        fields: [
            'slug',
            'video_id'
        ],
        sort: [
            'createdAt:DESC'
        ],
        pagination: {
            page: page,
            pageSize: 20,
        }
    }, {
        encodeValuesOnly: true,
    }))),
    searchVideoByTitle: (title) => (STRAPI_API.get('/videos?' + qs.stringify({
        filters: {
            title: {$containsi: title}
        },
        sort: ['bgg_expo:desc'],
        pagination: {
            page: 1,
            pageSize: 20,
        }
    }, {
        encodeValuesOnly: true,
    }))),
    getGameVideosTop: (gameId, pageSize = 20) => (STRAPI_API.get('/videos?' + qs.stringify({
        filters: {
            game_id: {$eq: gameId}
        },
        sort: ['bgg_expo:desc'],
        pagination: {
            page: 1,
            pageSize: pageSize,
        }
    }, {
        encodeValuesOnly: true,
    }))),
    getGameVideosNew: (gameId) => (STRAPI_API.get('/videos?' + qs.stringify({
        filters: {
            game_id: {$eq: gameId}
        },
        sort: ['post_date:desc'],
        pagination: {
            page: 1,
            pageSize: 20,
        }
    }, {
        encodeValuesOnly: true,
    }))),
    cacheGameVideos: (gameId) => (STRAPI_API.get(`/jobs/videos/game/${gameId}`)),
    getUserCollection: (username) => (STRAPI_API.get(`/bgg/collection/${username}`)),
    getGameVideosRecent: (gameId) => (STRAPI_API.get(`/videos?game_id=${gameId}&_limit=30&_sort=post_date:ASC`)),
    getChannelDetails: (channelId) => (STRAPI_API.get('/channels?' + qs.stringify({
        filters: {
            channel_id: {$eq: channelId}
        },
    }, {
        encodeValuesOnly: true,
    }))),
    getChannelThumbnail: (channelId) => (STRAPI_API.get('/channels?' + qs.stringify({
        filters: {
            channel_id: {$eq: channelId}
        },
        fields: ['thumbnail', 'name']
    }, {
        encodeValuesOnly: true,
    }))),
    getRecentChannelVideos: (channelId, page = 1, pageSize = 20) => (STRAPI_API.get('/videos?' + qs.stringify({
        filters: {
            channel_id: {$eq: channelId}
        },
        sort: ['post_date:desc'],
        pagination: {
            page: page,
            pageSize: pageSize,
        }
    }, {
        encodeValuesOnly: true,
    }))),
    getTopChannelVideos: (channelId) => (STRAPI_API.get('/videos?' + qs.stringify({
        filters: {
            channel_id: {$eq: channelId}
        },
        sort: ['bgg_expo:desc'],
        pagination: {
            page: 1,
            pageSize: 20,
        }
    }, {
        encodeValuesOnly: true,
    }))),
    getChannelsBySubscribers: () => (STRAPI_API.get('/channels?' + qs.stringify({
        filters: {
            $or: [{
                hidden: {
                    $null: true,
                },
            },{
                hidden: {
                    $eq: false
                }
            }]
        },
        sort: ['subscriber_count:desc'],
        pagination: {
            page: 1,
            pageSize: 30,
        }
    }, {
        encodeValuesOnly: true,
    })))
}
