import axios from 'axios'
import convert from 'xml-js';

import bggParseResponse from './bgg.model';
import config from '../config/config'

const BGG_API = axios.create({
    baseURL: config().BGG_API_URL
})

var options = {
    ignoreComment: true,
    alwaysChildren: true,
    compact: true
};

BGG_API.interceptors.response.use(onSuccess, onError)

function onSuccess(response) {
    const {status, statusText, data} = response

    if (status !== 200) {
        return Promise.reject(statusText)
    }
    return data
}

function onError(error) {
    const {
        response: {
            data: {err}
        }
    } = error
    return Promise.reject(err)
}

const reduceGameIDs = (items) => {
    let mappedIds = []

    if (Object.keys(items).includes('_attributes')) {
        //single value found
        mappedIds.push(items._attributes.id)
    } else {
        // multiple values found
        mappedIds = items.map(game => (game._attributes.id)).splice(0, 15)
    }

    return [...new Set(mappedIds)].join(',')
}

const parseMultipleGamesResult = (items) => {
    const parsed = items.map(game => {
        return bggParseResponse(game)
    }).filter(g => g !== false)

    return parsed.sort((a, b) => (b.owned > a.owned) ? 1 : ((a.owned > b.owned) ? -1 : 0))
}

const getGame = async (gameId) => {
    try {
        const data = await BGG_API.get(`/thing?id=${gameId}&stats=1`)

        let jsonObj = await convert.xml2js(data, options);

        if (jsonObj?.items?.item) {
            return bggParseResponse(jsonObj.items.item);
        } else {
            return []; // Not found
        }
    } catch (e) {
        console.log('bgg/getGame failed: ', e)
        return []
    }
}

const getGames = async (gameIds) => {
    try {
        const data = await BGG_API.get(`/thing?id=${gameIds}&stats=1`)

        let jsonObj = await convert.xml2js(data, options);

        if (jsonObj?.items?.item) {
            return parseMultipleGamesResult(jsonObj.items.item)
        } else {
            console.log('no results found')
            return []; // Not found
        }
    } catch (e) {
        console.log('bgg/getGame failed: ', e)
        return []
    }
}

const searchGames = async (searchTerm) => {
    try {
        searchTerm = searchTerm.replaceAll(' ', '+')
        let data = await BGG_API.get(`/search?query=${searchTerm}`)
        let jsonObj = await convert.xml2js(data, options);

        let games = []
        let gamesToReduce = []
        if (jsonObj?.items?.item) {
                const gameIds = reduceGameIDs(jsonObj.items.item)
                games = await getGames(gameIds)
        }

        if (games.length < 1) {
            data = await BGG_API.get(`/search?query=${searchTerm}&exact=1`)
            jsonObj = await convert.xml2js(data, options);

            gamesToReduce = jsonObj.items.item

            if(!Array.isArray(jsonObj.items.item)) {
                gamesToReduce = [jsonObj.items.item]
            }

            games = await getGames(reduceGameIDs(gamesToReduce))
        } else {
            return games
        }


        return games
    } catch (e) {
        console.log('bgg/searchGame failed: ', e)
        return []
    }
}

const getHotGames = async () => {
    const response = await axios.get('https://api.geekdo.com/api/hotness?geeksite=boardgame&objecttype=thing')
    const {items = []} = response

    const hotGamesCleaned = items.map(g => (
        {
            id: g.objectid,
            name: g.name,
        }
    ))

    return hotGamesCleaned.map(g => (g.id))
}

export default {
    getGame,
    searchGames,
    getHotGames
}
