import React from 'react'
import Link from 'next/link'
import {useSelector, useDispatch} from 'react-redux'

import {CubeIcon, FilmIcon, NewspaperIcon, HomeIcon, SearchIcon} from '@heroicons/react/solid'

import Search from '../search/search'
import {toggleLeftMenu} from "../../../store/reducers/layoutSlice";

function Drawer() {
    const layoutState = useSelector(state => state.layout)
    const {leftMenuOpen, rightMenuOpen} = layoutState
    const dispatch = useDispatch()

    return (
        <div className={`side-drawer-side ${leftMenuOpen ? 'left-open' : ''}`}>
            <label onClick={() => {dispatch(toggleLeftMenu())}} className="side-drawer-overlay"/>
            <div className='p-4 overflow-y-auto w-leftDrawer bg-slate-50 border-r-1 border-slate-500'>
                <h3 className='text-center text-xl font-bold mb-4 block w-full' onClick={() => {dispatch(toggleLeftMenu())}}>Board Games
                    Videos</h3>
                <ul className='my-2 border-b-1 mb-6 pb-2'>
                    <li>
                        <Link href='/' className={'text-lg'}>
                            <a className="flex flex-row py-2" onClick={() => {dispatch(toggleLeftMenu())}}>
                                <HomeIcon className='w-5 h-5'/>
                                <span className='pl-2 '>Home</span>
                            </a>
                        </Link>
                    </li>
                    <li>
                        <Link href='/videos' className={'text-lg'}>
                            <a className="flex flex-row py-2" onClick={() => {dispatch(toggleLeftMenu())}}>
                                <FilmIcon className='w-5 h-5'/>
                                <span className='pl-2 '>Videos</span>
                            </a>
                        </Link>
                    </li>
                    <Link href='/games' className={'text-lg'}>
                        <a className="flex flex-row py-2" onClick={() => {dispatch(toggleLeftMenu())}}>
                            <CubeIcon className='w-5 h-5'/>
                            <span className='pl-2 '>Games</span>
                        </a>
                    </Link>
                    <Link href='/search' className={'text-lg'}>
                        <a className="flex flex-row py-2" onClick={() => {dispatch(toggleLeftMenu())}}>
                            <SearchIcon className='w-5 h-5'/>
                            <span className='pl-2 '>Search</span>
                        </a>
                    </Link>
                    <Link href='/channels' className={'text-lg hidden'}>
                        <a className="flex flex-row py-2 hidden" onClick={() => {dispatch(toggleLeftMenu())}}>
                            <NewspaperIcon className='w-5 h-5'/>
                            <span className='pl-2 '>Channels</span>
                        </a>
                    </Link>
                </ul>

                <div className={'h-120 overflow-scroll'}>

                </div>
            </div>
        </div>
    )
}

export default Drawer
