const slugify = require("slugify");
const SLUG_CLEAN_REGEX = /[*+~.$%^&`<>\-_=()\[\]'"?!{}:;@#\\#\/,|]/g

const slugifyGame = (gameId, gameName = '') => {
    return slugify(gameId + '-' + gameName.toLowerCase(), {
        trim: true,
        remove: SLUG_CLEAN_REGEX
    })
}

module.exports = {
    slugifyGame
}