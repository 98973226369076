import devConf from './env.dev.json'
import prodConf from './env.prod.json'

/* eslint-disable prefer-destructuring */
const environment = process.env.environment
/* why? -> https://nextjs.org/docs/api-reference/next.config.js/environment-variables */

const getConfig = () => {
  switch (environment) {
    case 'development':
    case 'staging':
    default:
      return devConf
    case 'production':
      return prodConf
  }
}

export default getConfig
