const getRatingColour = (rating) => {
    let colour = 'bg-slate-400'

    if(rating >= 8) {
        colour = 'bg-emerald-500'
    } else if (rating >= 6) {
        colour = 'bg-sky-500'
    } else if (rating >= 4) {
        colour = 'bg-orange-400'
    } else if (rating >= 0) {
        colour = 'bg-red-400'
    }
    return colour
}

module.exports = {
    getRatingColour
}