import React from 'react'
import Link from 'next/link'
import {useDispatch} from "react-redux";

import {SearchIcon} from "@heroicons/react/solid";

import {toggleLeftMenu, toggleRightMenu} from "../../../store/reducers/layoutSlice";

const Navbar = () => {
    const dispatch = useDispatch()
    return (
        <nav className='navbar bg-neutral text-neutral-content sticky top-0 z-10'>
            <div className='container py-2 mx-auto flex justify-between items-center w-full'>
                <div className='flex items-center justify-between'>
                    <div className="flex-none sm:hidden p-2 mr-1" onClick={() => {dispatch(toggleLeftMenu())}}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                             className="inline-block w-6 h-6 stroke-current"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5"
                                  d="M4 6h16M4 12h16M4 18h16"></path>
                        </svg>
                    </div>
                    <Link href='/'>
                        <a className='font-bold text-2xl pl-2 flex flex-row items-center'>
                            <img className='h-9 w-auto mr-2' src={'https://res.cloudinary.com/ddtcn4crx/image/upload/v1669568030/boardgame-video-logo_uczre2.svg'} alt={'Board game Videos'}/>
                            </a>
                    </Link>
                </div>
                <div className={`flex items-center pt-0 border-0`}>
                    <div className='hidden sm:flex flex-row mx-4 p-2 p-0.5'>
                        <Link href='/videos'
                              className='my-1 text-white dark:text-white md:mx-4 md:my-0'>Videos</Link>
                    </div>
                    <div className='hidden sm:flex flex-row mx-4 p-2 p-0.5'>
                        <Link href='/games'
                              className='my-1 text-white dark:text-white md:mx-4 md:my-0'>Games</Link>
                    </div>
                    <div className='flex flex-row mx-4 p-0.5 cursor-pointer'>
                        <Link href='/?search=true' as={'/search'}
                              className='my-1 text-white dark:text-white md:mx-4 md:my-0'>
                            <SearchIcon className={'w-6 h-6'}/>
                        </Link>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Navbar
