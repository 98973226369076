import React, {useEffect, useState} from 'react'
import {useRouter} from 'next/router'
import {SearchIcon} from "@heroicons/react/solid";

import StrapiAPI from '../../data/strapi';
import BggAPI from '../../data/bgg';

import VideoThumb from '../videoThumb/videoThumb'

import {getRatingColour} from '../../utils/rating';
import {slugifyGame} from "../../utils/slug";
import Image from "next/future/image";

function SearchContainer(props) {
    const [searchTerm, setSearchTerm] = useState('')
    const [videos, setVideos] = useState([])
    const [games, setGames] = useState([])
    const [loading, setLoading] = useState(false)
    const [searching, setSearching] = useState(false)
    const router = useRouter()

    useEffect(() => {
        if (searching) {
            return
        }

        const delayDebounceFn = setTimeout(() => {
            if (searchTerm.length >= 3) {
                _search()
            } else if (searchTerm === '') {
                setVideos([])
                setGames([])
            }
        }, 1000)
        return () => clearTimeout(delayDebounceFn)
    }, [searchTerm])

    const _gameClick = (game) => {
        router.push(`/games/${slugifyGame(game.id, game.name)}`)
    }

    const _search = async () => {
        document.querySelector('[type=search].search').blur()
        setSearching(true)
        setVideos([])
        setGames([])
        setLoading(true)
        const videos = await StrapiAPI.searchVideoByTitle(searchTerm)
        const games = await BggAPI.searchGames(searchTerm)
        setGames(games)
        setVideos(videos.data)
        setLoading(false)
        setSearching(false)
    }

    return (
        <div className={`w-full ${props.fullHeight ? 'h-screen' : 'h-auto'}`}>
            <div className='mb-5 mt-2 flex mx-2'>

                <div className="input-group h-12 shadow-lg rounded-md border border-slate-100">
                    <button className="btn btn-square bg-transparent border-none py-2 px-2 text-gray-600">
                        <SearchIcon/>
                    </button>
                    <input
                        autoFocus
                        type='search'
                        autoComplete='off'
                        className='search px-2 placeholder-gray-400 text-gray-600 bg-white border-none text-xl border outline-none focus:outline-none w-full'
                        placeholder='Search for games and videos'
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>


            </div>

            {loading && <div className='text-center animate-pulse'>
                searching ..
            </div>
            }


            {(!loading && videos.length < 1 && games.length < 1 && searchTerm.length > 3) &&
                <div className='w-full text-center mt-10'>no results found</div>}

            {games.length > 0 && <div className='w-full h-auto my-6 overflow-scroll border-b-1 pb-2'>
                <h6 className='ml-2 text-lg'>Games</h6>
                <div className='flex flex-col pt-0.5 pb-2 overflow-x-hidden overflow-y-scroll h-80'>
                    {games.map((game, index) => {
                            const {name, thumbnail, rating, players, playtime, weight, yearpublished} = game
                            return (
                                <div
                                    className={`shadow flex flex-row w-full cursor-pointer bg-slate-50 my-1 h-28 rounded-lg p-2`}
                                    onClick={() => (_gameClick(game))} key={index}>
                                    <div className='w-25'>
                                        <Image
                                            src={thumbnail}
                                            loading='lazy'
                                            alt={name}
                                            className={''}
                                            sizes="100vw"
                                            style={{width: 'auto', height: '5rem'}}
                                            width={200}
                                            height={150}
                                        />
                                    </div>
                                    <div className='flex flex-col ml-3 grow-0'>
                                        <h4 className='text-lg font-medium line-clamp-2 mb-1 mt-0'>{name} &nbsp;({yearpublished})</h4>
                                        <div className='flex flex-row flex-nowrap justify-around max-sm w-64'>
                                            <div
                                                className={`rounded-full text-md text-white font-bold h-10 w-10 flex text-center items-center justify-center justify-center ${getRatingColour(rating)} flex-col`}>{rating}
                                                <br/> <span className='text-xxxs h-1 -translate-y-1.5'>rating</span>
                                            </div>
                                            <div className='border-l-1 border-gray-300 h-10'/>
                                            <div
                                                className='rounded-full text-md font-bold h-10 w-10 flex text-center items-center justify-center bg-yellow-400 flex-col'>{weight}<br/>
                                                <span className='text-xxxs h-1 -translate-y-1.5'>weight</span>
                                            </div>
                                            <div className='border-l-1 border-gray-300 h-10'/>
                                            <div
                                                className='items-center text-md text-bold h-10 justify-center text-center pt-1'>
                                                {playtime}
                                                <span className='block text-xxs -mt-1'>playtime</span>
                                            </div>
                                            <div className='border-l-1 border-gray-300 h-10'/>
                                            <div
                                                className='items-center text-md text-bold h-10 justify-center text-center pt-1'>
                                                {players}
                                                <span className='block text-xxs -mt-1'>players</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    )}
                </div>
            </div>
            }

            {videos.length > 0 &&
                <React.Fragment>
                    <h6 className='ml-2 mb-2 mt-4 text-lg'>Videos</h6>
                    <div className='flex flex-row mb-4 h-auto items-center overflow-y-hidden overflow-x-scroll'>
                        {videos.map((video, index) => {
                            const {attributes, attributes: {video_id}} = video
                            return (
                                <div key={`${video_id}${index}`} className='mb-4 w-80 '>
                                    <VideoThumb {...attributes} index={index}/>
                                </div>
                            )
                        })
                        }
                    </div>
                </React.Fragment>}
        </div>
    )
}

export default SearchContainer
