import React from 'react'

import {useRouter} from "next/router";

import Search from '../../components/search/search'
import ModalLayout from "../../components/modalLayout/modalLayout";

function SearchContainer() {
    const router = useRouter()
    const {query: {search = false}} = router

    return (
        <React.Fragment>
            <ModalLayout
                isOpen={search}
                backRoute='/'
            >
                <div className={'bg-white px-2 py-4 h-full rounded-t-2xl overflow-scroll'}>
                    <Search fullHeight={true}/>
                </div>
            </ModalLayout>
        </React.Fragment>
    )
}

export default SearchContainer
