import React, {useEffect} from 'react'

import {useSelector} from 'react-redux'

import Footer from '../../components/footer/footer'
import Navbar from '../../components/navbar/navbar'
import Drawer from '../../components/drawer/drawer'
import Search from '../../containers/search/search'

function Layout(props) {
    const {children} = props
    const layoutState = useSelector(state => state.layout)
    const {leftMenuOpen} = layoutState

    return (
        <div className='layout bg-white'>
            <div className='side-drawer'>
                <div className={`side-drawer-content flex flex-col ${leftMenuOpen ? 'left-open' : ''}`}>
                    <Navbar/>
                    <Search/>
                    <div className='container pb-1 mx-auto grow w-full md:w-200 lg:w-200 xl:w-200'>
                        {children}
                    </div>
                    <Footer/>
                </div>
                <Drawer/>
            </div>
        </div>
    )
}

export default Layout
