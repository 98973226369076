import React, {useState, useEffect} from "react";
import Modal from "react-modal";
import {useRouter} from "next/router"
import {XIcon} from "@heroicons/react/solid";

Modal.setAppElement('#__next')

function ModalLayout(props) {
    const {isOpen, onRequestClose, backRoute = false} = props

    const router = useRouter()

    const [showModal, setShowModal] = useState(false)

    /*
    useEffect(() => {
        if (typeof window !== 'undefined') {
            setShowModal(true)
        }
    }, [])
    */

    useEffect(() => {
        setShowModal(isOpen)

    }, [isOpen])

    const close = async () => {
        if (backRoute && router.isReady) {
            await router.push(backRoute);
        }
        // start fade out?
        setShowModal(false)
    }

    return (
        <Modal
            isOpen={showModal}
            onRequestClose={onRequestClose}
        >
            <div className={'px-0 h-full relative'}>
                <XIcon className={'w-8 h-8 absolute right-4 xs:-top-16 -top-11 z-10 text-slate-50 cursor-pointer'} onClick={() => close()}/>
                <div className={'mt-14 lg:mt-20'}>
                    {props.children}
                </div>
            </div>
        </Modal>

    )
}

export default ModalLayout
