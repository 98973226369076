import React from 'react'
import Link from 'next/link'

const Footer = () => {
    return (
        <div className="footer items-center p-4 bg-neutral text-neutral-content">
            <div className="items-center grid-flow-col">
                <p>2022</p>
            </div>
            <div className="grid-flow-col gap-4 md:place-self-center md:justify-self-end">
                <Link href='/about' className='text-gray-600 hover:text-gray-800'>About</Link>
                <Link href='/privacy-policy' className='text-gray-600 hover:text-gray-800'>Privacy Policy</Link>
                <Link href='/cookie-policy' className='text-gray-600 hover:text-gray-800'>Cookie Policy</Link>
            </div>
        </div>
    )
}

export default Footer