import React, {useEffect, useRef, useState, useMemo} from 'react';
import Image from 'next/future/image'
import Link from 'next/link';
import PropTypes from 'prop-types';

// import {PTtoReadable} from '../../utils/time'

import StrapiAPI from "../../data/strapi";

const VideoList = (props) => {
    const thumbRef = useRef(null);

    const isInViewport = useIsInViewport(thumbRef);

    const [channelThumb, setChannelThumb] = useState(false);


    const {video_id, title = '', index = 1, duration, type, slug, channel_id} = props
    const cleanTitle = title.replace(/[^\x00-\x7F]/g, '')
    const cleanDuration = false //PTtoReadable(duration)
    const cleanType = false //type === 'other' ? false : type

    useEffect(() => {
        if (isInViewport && !channelThumb) {
            StrapiAPI.getChannelThumbnail(channel_id).then(response => {
                const {data} = response
                const channel = data[0]
                if (channel?.attributes) setChannelThumb(channel.attributes.thumbnail)

            })
        }
    }, [isInViewport])

    return (
        <div className={'mx-2'} ref={thumbRef}>
            <div className={'relative'}>
                    <div className={'clip-youtube-parent shadow-md'}>
                        <Image
                            src={`https://img.youtube.com/vi/${video_id}/hqdefault.jpg`}
                            loading='lazy'
                            alt={title}
                            className={'clip-youtube-thumb'}
                            width={298}
                            height={224}
                        />
                       <Link href={`/videos/${slug}`} as={`/videos/${slug}/`} passHref>
                           <a className={'btn-play'}/>
                       </Link>


                        {cleanType && <span
                            className={'absolute right-1.5 bottom-1.5 text-xs border-gray-400 border-1 px-1.5 font-medium bg-gray-100 text-gray-800 rounded py-0.5 uppercase'}>{cleanType}</span>}
                        {cleanDuration && <span
                            className={'absolute right-1.5 top-1.5 text-xs border-gray-400 border-1 px-1.5 font-medium bg-gray-100 text-gray-800 rounded py-0.5'}>{cleanDuration}</span>}

                    </div>
                    <div className='flex flex-row h-10'>
                         <div className={`flex flex-col justify-center w-9 h-9 mr-1.5 pt-1.5`}>
                            {channelThumb &&
                                <img
                                    className='rounded-full w-9 h-auto bg-slate-100 shadow'
                                    src={channelThumb.toString()}
                                    alt={''}
                                    referrerPolicy="no-referrer"
                                    onError={(e) => (e.currentTarget.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=')}
                                />}
                        </div>
                        <h5 className={'flex flex-col justify-center mt-0.5'}>
                            <span className={'text-sm line-clamp-2'}>{cleanTitle}</span>
                        </h5>
                    </div>
                </div>
        </div>
    );
}

function useIsInViewport(ref) {
    const [isIntersecting, setIsIntersecting] = useState(false);

    const observer = useMemo(
        () => {
            if (typeof window !== 'undefined') {
                return new IntersectionObserver(([entry]) =>
                        setIsIntersecting(entry.isIntersecting), {
                        threshold: 0
                    }
                )
            } else {
                return false
            }
        },
        [],
    )

    useEffect(() => {
        if (observer) {
            observer.observe(ref.current);

            return () => {
                observer.disconnect();
            };
        }
    }, [ref, observer]);

    return isIntersecting;
}

VideoList.propTypes = {
    video_id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    index: PropTypes.number
};

export default VideoList;

